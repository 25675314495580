import * as React from "react"
import Layout from "../components/layout";

// Styles
import "../styles.css"

import Content from "../content";
import Container from "../theme/container";
import Heading from "../theme/heading";


const Credits = () => {
    return (
        <>
            <Layout contact={Content.Contact}>
                <section>
                    <Container>
                        <Heading as="h1" align="center">Consultation</Heading>
                        <center>
                            <p>Schedule a 30 minute consultation with us to see how we can help you secure your online busisness.</p>
                            <Calendly />
                        </center>
                    </Container>
                </section>

            </Layout>
        </>
    );
}

class Calendly extends React.Component {
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    }

    componentWillUnmount() {
        // whatever you need to cleanup the widgets code
    }

    render() {
        return (
            <div>
                <div id="schedule_form">
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/faheem-khan"
                        style={{ minWidth: '320px', height: '650px' }} />
                </div>
            </div>
        );
    }
}

export default Credits;